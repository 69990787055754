<template>
  <div>
    <el-dialog
      @close="closePanel"
      width="45%"
      :title="views == '1' ? '新增盲盒信息' : '新增特权信息'"
      :visible.sync="dialogShow"
      :close-on-click-modal="modal"
    >
      <el-form
        v-if="views == '1'"
        :model="formPrize"
        label-width="120px"
        :rules="rulesPrize"
        ref="myForm"
        required
      >
        <el-form-item label="奖品藏品:" prop="NFTID">
          <el-select placeholder="请选择奖品藏品" v-model="formPrize.NFTID">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.NFTName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量：" prop="ceiling">
          <el-input
            placeholder="请输入数量"
            v-model="formPrize.ceiling"
            type="number"
          ></el-input>
        </el-form-item>
      </el-form>

      <el-form
        v-else-if="views == '2'"
        :model="formRight"
        label-width="120px"
        :rules="rulesRight"
        ref="myForm"
        required
      >
        <el-form-item label="特权名称：" prop="name">
          <el-input
            placeholder="请输入特权名称"
            v-model="formRight.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="特权描述：" prop="desc">
          <el-input
            placeholder="请输入特权描述"
            v-model="formRight.desc"
          ></el-input>
        </el-form-item>
        <el-form-item label="特权截止日期：" prop="endDate">
          <el-date-picker
            v-model="formRight.endDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="请选择特权截止日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="btn">
        <el-button @click="closePanel" size="small">取 消</el-button>
        <el-button type="primary" @click="doValidate" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { NftQuery } from "@/api/module/nft";
import { number } from "echarts";

let Brarules2 = {
  endDate: [
    {
      required: true,
      message: "请选择特权截止日期",
      trigger: "change",
    },
  ],
  desc: [
    {
      required: true,
      message: "请输入特权描述",
      trigger: "blur",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入特权名称",
      trigger: "blur",
    },
  ],
};
let Brarules1 = {
  NFTID: [
    {
      required: true,
      message: "请选择藏品奖品",
      trigger: "change",
    },
  ],
  ceiling: [
    {
      required: true,
      message: "请输入数量",
      trigger: "blur",
    },
  ],
};
export default {
  name: "RightPanel",
  props: ["dialogShow", "views"],
  data() {
    return {
      rulesPrize: Brarules1,
      rulesRight: Brarules2,
      modal: false,
      options: [],
      formPrize: {
        NFTID: "",
        ceiling: "",
      },
      formRight: {
        desc: "",
        endDate: "",
        name: "",
      },
    };
  },
  created() {
    this.doQuery();
  },
  methods: {
    doQuery() {
      let query = `?pagination=false&page=1&pageSize=10&status=3`;
      NftQuery(query).then((r) => {
        this.options = r.list;
      });
    },
    setVal() {
      let oData;
      if (this.views == "1") {
        let f = this.formPrize;
        oData = {
          NFTID: f.NFTID,
          ceiling: Number(f.ceiling),
        };
      } else {
        let f = this.formRight;
        oData = {
          desc: f.desc,
          endDate: f.endDate,
          name: f.name,
        };
      }
      return oData;
    },
    doValidate() {
      let oData = this.setVal();
      this.$refs.myForm.validate(async (val) => {
        if (val) {
          //   全局验证通过;
          this.$message({
            type: "success",
            message: "添加成功",
            duration: 1300,
          });
          this.$emit("update:dialogShow", false);
          //调用父组件的方法
          this.$emit("updata", oData);
          this.$refs["myForm"].resetFields(); // 在初始化所有字段
        } else {
          //全局验证不通过
        }
      });
    },
    closePanel() {
      (this.formPrize = {
        NFTID: "",
        ceiling: "",
      }),
        (this.form = {
          desc: "",
          endDate: "",
          name: "",
        });
      this.$emit("update:dialogShow", false);
      this.$refs.myForm.resetFields();
    },
  },
};
</script>

